<template>
  <div class="banner relative h-screen overflow-visible mb-16 text-white flex flex-col justify-center items-center pt-20" id="banner">
    <div class="text-center mb-10">
      <div class="text-4xl">Fintech Pioneer Of Cryptocurrency</div>
      <div class="text-2xl mt-10 px-10">
        The first specialized financial derivative service provider in crypto industry.
      </div>
    </div>

    <img src="../../assets/images/banner_icon_mod.svg" class="pb-10 pt-5 w-5/6" />
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  .banner {
    background-image: url(../../assets/images/banner_bg.svg);
  }

</style>
