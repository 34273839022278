<template>
  <div>
    <pc-banner class="hidden md:block"></pc-banner>
    <mobile-banner class="block md:hidden"></mobile-banner>
  </div>
</template>

<script>
  import PcBanner from './PcBanner'
  import MobileBanner from './MobileBanner'

  export default {
    components: {
      PcBanner,
      MobileBanner,
    },
  }
</script>

<style lang="scss" scoped>
</style>
