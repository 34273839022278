<template>
  <div class="home-data mb-10" id="home-data">
    <div class="content hidden lg:flex">
      <div class="item">
        <img src="../assets/images/data_icon1.svg" class="icon" />
        <div class="name">Clients</div>
        <div class="label">50+</div>
      </div>
      <div class="item">
        <img src="../assets/images/data_icon2.svg" class="icon" />
        <div class="name">Venture investments</div>
        <div class="label">100+</div>
      </div>
      <div class="item">
        <img src="../assets/images/data_icon3.svg" class="icon" />
        <div class="name">AUM</div>
        <div class="label">500M+</div>
      </div>
      <div class="item">
        <img src="../assets/images/data_icon4.svg" class="icon" />
        <div class="name">Monthly turnover</div>
        <div class="label">50B+</div>
      </div>
    </div>
    <div class="content-m flex lg:hidden">
      <div class="flex flex-row">
        <div class="item">
          <img src="../assets/images/data_icon1.svg" class="icon" />
          <div class="name">Clients</div>
          <div class="label">50+</div>
        </div>
        <div class="item">
          <img src="../assets/images/data_icon2.svg" class="icon" />
          <div class="name">Venture investments</div>
          <div class="label">100+</div>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="item">
          <img src="../assets/images/data_icon3.svg" class="icon" />
          <div class="name">AUM</div>
          <div class="label">500M+</div>
        </div>
        <div class="item">
          <img src="../assets/images/data_icon4.svg" class="icon" />
          <div class="name">Monthly turnover</div>
          <div class="label">50B+</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  .home-data {
    min-height: 21.25vw;
    background-color: #fff;
  }

  .home-content {
    display: flex;
    flex-direction: row;
  }

  .content {
    position: absolute;
    margin-top: -10vw;
    width: 80vw;
    height: 21.25vw;
    flex-direction: row;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 3px 41px 78px 9px rgba(174, 170, 221, 0.14);
  }

  .content .item {
    flex: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content .item .icon {
    width: 5.2vw;
  }

  .content .item .name {
    font-size: 1.146vw;
    font-width: 400;
    color: #111947;
    margin-top: 0.83vw;
  }

  .content .item .label {
    font-size: 3.645vw;
    color: #111947;
    font-width: blod;
    argin-top: 2.08vw;
  }

  .content-m {
    flex-direction: column;
  }

  .content-m .item {
    flex: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .content-m .item .icon {
    width: 4rem;
  }

  .content-m .item .name {
    font-size: 1rem;
    font-width: 400;
    color: #111947;
    margin-top: 1rem;
  }

  .content-m .item .label {
    font-size: 2rem;
    color: #111947;
    font-width: blod;
    argin-top: 2.5rem;
  }

</style>
