<template>
  <div class="banner relative overflow-visible pb-48 text-white" id="banner">
    <img src="../../assets/images/banner_icon_mod.svg" class="absolute pb-10 pt-16 pr-48 right-0 w-3/5 max-h-screen" />

    <div class="h-screen max-w-6xl mx-auto pb-20 flex flex-col justify-center items-start z-20 relative">
      <div class="text-5xl w-2/5">Fintech Pioneer Of Cryptocurrency</div>
      <div class="text-2xl mt-10 w-1/3">
        The first specialized financial derivative service provider in crypto industry.
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  .banner {
    background-image: url(../../assets/images/banner_bg.svg);
  }

</style>
