<template>
  <div class="home">
    <my-header></my-header>
    <Banner></Banner>
    <Data />
    <CoreBusiness />
    <BusinessData />
    <my-footer></my-footer>
  </div>
</template>

<script>
  import MyHeader from '../components/MyHeader'
  import Banner from '../components/Banner/index'
  import Data from '../components/HomeData'
  import CoreBusiness from '../components/CoreBusiness'
  import BusinessData from '../components/BusinessData'
  import MyFooter from '../components/MyFooter'

  export default {
    components: {
      MyHeader,
      Banner,
      Data,
      CoreBusiness,
      BusinessData,
      MyFooter,
    },
  }
</script>

<style lang="scss" scoped>

</style>
