import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faReddit, faTwitter, faTelegram, faDiscord, faGithub, faAndroid, faApple } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight, faBook, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import {} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './assets/styles/tailwind.css'
import './assets/styles/main.scss'
import App from './App.vue'
import router from './router'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

library.add(
  faReddit, faTwitter, faTelegram, faDiscord, faGithub,
  faArrowRight, faBook, faQuoteLeft, faAndroid, faApple,
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueAnalytics, {
  id: '',
  router,
  // autoTracking: {
  //   screenview: true,
  //   transformQueryString: false,
  // },
  debug: {
    enabled: false,
  },
})
Vue.config.devtools = true
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
