<template>
  <div class="business-data" id="business-data">
    <div class="label">Business Data</div>
    <div class="content flex flex-col lg:flex-row text-white">
      <div class="item relative">
        <swiper class="swiper" :options="swiperOptions">
          <swiperSlide v-for="i in leftItems" class="swiper-item item_bg1">
            <div class="flex flex-col justify-between items-start h-full pb-24 pt-10 px-10">
              <div class="text-xl lg:text-3xl font-bold">Our people advance our understanding of the industry.</div>
              <div class="text-3xl lg:text-6xl">{{i.title}}</div>
              <div class="text-xl lg:text-2xl">{{i.subtitle}}</div>
            </div>
          </swiperSlide>
          <div class="px-10 swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="item">
        <swiper class="swiper" :options="swiperOptions">
          <swiperSlide v-for="i in rightItems" class="swiper-item item_bg2">
            <div class="flex flex-col justify-between items-start h-full pb-24 pt-10 px-10">
              <div class="text-xl lg:text-3xl font-bold">Our track record distinguishes us from others.</div>
              <div class="text-2xl lg:text-6xl">{{i.title}}</div>
              <div class="text-xl lg:text-2xl">{{i.subtitle}}</div>
            </div>
          </swiperSlide>
          <div class="px-10 swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
  import { swiper, swiperSlide } from 'vue-awesome-swiper'

  export default {
    name: 'HomeBusinessData',
    components: {
      swiper,
      swiperSlide,
    },
    data() {
      return {
        swiperOptions: {
          autoplay: true,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true, //允许分页点击跳转
          },
        },
        leftItems: [
          {
            title: '72%',
            subtitle: 'Members in Research & Development roles',
          },
          {
            title: '3.2',
            subtitle: 'Average years in crypto industry',
          },
          {
            title: '100%',
            subtitle: 'Advanced degrees among our members',
          },
        ],
        rightItems: [
          {
            title: '100+',
            subtitle: 'Customers Served',
          },
          {
            title: '$50B+',
            subtitle: 'Monthly turnover',
          },
          {
            title: '$500M+',
            subtitle: 'Asset Under Management',
          },
        ],
      }
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.swiper
      },
    },
  }
</script>

<style lang="scss" scoped>
  .business-data {
    margin: 3rem 10vw;
  }

  .label {
    font-size: 2rem;
    color: #111947;
    text-align: center;
    font-width: 400;
  }

  .content {
    margin-top: 4rem;
  }

  .content .item {
    flex: 1;
    overflow: hidden;
    position: relative;
  }

  .swiper {
    height: 100vw;
  }

  .item_bg1 {
    background: linear-gradient(119deg, #0C0079, #004BD5);
  }

  .item_bg2 {
    background: #1e1e2a;
  }

  .business-data .swiper-pagination {
    text-align: left;
    bottom: 3rem;
  }

  @media (min-width: 768px) {
    .label {
      font-size: 4.3rem;
      color: #111947;
      text-align: center;
      font-width: 400;
    }

    .swiper {
      height: 32vw;
    }
  }
</style>
