<template>
  <div class="max-w-6xl mx-auto" id="core-business">
    <div class="text-center text-4xl md:text-6xl text-black">Core Business</div>
    <div class="flex flex-col md:flex-row justify-between items-center mt-8">
      <img src="../assets/images/core_icon1_mod.svg" class="w-3/4 md:w-1/2" />
      <div class="md:w-2/5 text-center md:text-left md:mx-20 mb-4">
        <div class="text-2xl md:text-4xl text-black my-4">Derivatives Service</div>
        <!-- <div class="flex flex-row justify-start items-center my-8 hidden md:block">
          01
          <div class="ml-5 w-40 border-b border-black" style="border-bottom: 1px solid #111947"></div>
        </div> -->
        <div class="mb-4">Professional service on crypto derivatives.</div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row-reverse justify-between items-center mt-8">
      <img src="../assets/images/core_icon2_mod.svg" class="w-3/4 md:w-1/2" />
      <div class="md:w-2/5 text-center md:text-left md:mx-20 mb-4">
        <div class="text-2xl md:text-4xl text-black my-4">Liquidity Support</div>
        <!-- <div class="flex flex-row justify-start items-center my-8 hidden md:block">
          02
          <div class="ml-5 w-40 border-b border-black" style="border-bottom: 1px solid #111947"></div>
        </div> -->
        <div class="mb-4">Liquidity support for flexible, various needs.</div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row justify-between items-center mt-8">
      <img src="../assets/images/core_icon3_mod.svg" class="w-3/4 md:w-1/2" />
      <div class="md:w-2/5 text-center md:text-left md:mx-20 mb-4">
        <div class="text-2xl md:text-4xl text-black my-4">Quant Trading</div>
        <!-- <div class="flex flex-row justify-start items-center my-8 hidden md:block">
          03
          <div class="ml-5 w-40 border-b border-black" style="border-bottom: 1px solid #111947"></div>
        </div> -->
        <div class="mb-4">Data driven and intelligence intensive quant trading strategy.</div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row justify-between items-center mt-8">
      <div class="md:w-2/5 text-center md:text-left md:mx-20 mb-4">
        <div class="text-2xl md:text-4xl text-black my-4">Venture Capital</div>
        <!-- <div class="flex flex-row justify-start items-center my-8 hidden md:block">
          04
          <div class="ml-5 w-40 border-b border-black" style="border-bottom: 1px solid #111947"></div>
        </div> -->
        <div class="mb-4">Selective and proactive layout for crypto future.</div>
      </div>
    </div>
    <div class="mt-2 mb-10 max-w-6xl mx-auto flex flex-row justify-center items-center flex-wrap" style="padding: 0 5%">
      <img class="w-1/2 md:w-1/5" src="../assets/images/logo1.svg" />
      <img class="w-1/2 md:w-1/5" src="../assets/images/logo2.svg" />
      <img class="w-1/2 md:w-1/5" src="../assets/images/logo3.svg" />
      <img class="w-1/2 md:w-1/5" src="../assets/images/logo4.svg" />
      <img class="w-1/2 md:w-1/5" src="../assets/images/logo5.svg" />
      <img class="w-1/2 md:w-1/5" src="../assets/images/logo6.svg" />
      <img class="w-1/2 md:w-1/5" src="../assets/images/logo7.svg" />
      <img class="w-1/2 md:w-1/5" src="../assets/images/logo8.svg" />
      <img class="w-1/2 md:w-1/5" src="../assets/images/logo9.svg" />
      <img class="w-1/2 md:w-1/5" src="../assets/images/logo10.svg" />
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
</style>
